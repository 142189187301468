<template>
  <div>
    <title>SIM INVENTORY ~ ADD DATA BUYER ORDER</title>
    <section class="content-header">
      <h1>
        Add Data Buyer Order
        <br />
        <h4>Please enter buyer order data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Buyer Order Transactions</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Buyer Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number </label>
                  <input
                    type="text"
                    v-model="isidata.odernobuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Date</label>
                  <input
                    type="date"
                    v-model="isidata.tglorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="isidata.qtyorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer</label>
                  <select2
                    :data="buyerlist"
                    :value="valuebuyer"
                    @update="updatebuyer($event)"
                  ></select2>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="isidata.notesorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product</label>
                  <select2
                    :data="productlist"
                    :value="valueproduct"
                    @update="updateproduct($event)"
                  ></select2>
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/buyer-order">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Buyer Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date Production </label>
                  <input
                    type="date"
                    v-model="isidata.mulai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date Production </label>
                  <input
                    type="date"
                    v-model="isidata.selesai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Packaging</label>
                  <input
                    type="text"
                    v-model="isidata.pack"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT Value</label>
                  <input
                    type="text"
                    v-model="isidata.cmtval"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT</label>
                  <select
                    name="cmt"
                    class="form-control"
                    v-model="isidata.cmt"
                    id="cmtl"
                  >
                    <option value="APPWORK">App Work</option>
                    <option value="FOB">FOB</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Status</label>
                  <select
                    name="status"
                    class="form-control"
                    v-model="isidata.status"
                    id="statuss"
                  >
                    <option value="CMT">CMT</option>
                    <option value="FOB">FOB</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">FOB</label>
                  <input
                    type="text"
                    v-model="isidata.fob"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Shipment</label>
                  <input
                    type="text"
                    v-model="isidata.shipment"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Term</label>
                  <input
                    type="text"
                    v-model="isidata.termcond"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Consignee</label>
                  <input
                    type="text"
                    v-model="isidata.cons"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Currency</label>
                  <input
                    type="text"
                    v-model="isidata.curr"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprod",
  data() {
    return {
      loading: false,
      isidata: {
        pack: "",
        cmt: "",
        fob: "",
        shipment: "",
        termcond: "",
        cons: "",
        curr: "",
        odernobuyer: "",
        tglorderbuyer: "",
        qtyorderbuyer: "",
        notesorderbuyer: "",
        mulai: "",
        selesai: "",
        cmtval: "",
        status: ""
      },
      buyerlist: [],
      productlist: [],
      valuebuyer: "",
      valueproduct: ""
    };
  },
  created() {
    this.getnow();
    this.getbuyerlist();
    this.getproductlist();
  },
  methods: {
    updatebuyer(value) {
      this.valuebuyer = value;
    },
    updateproduct(value) {
      this.valueproduct = value;
    },
    getbuyerlist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbynomor_produk?nomor_produk=" + this.isidata.noprodh;
      const urlAPIget = this.$apiurl + "buyer/getlistbuyer";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.buyerlist = resp.data.data;
          console.log(this.buyerlist);
          this.buyerlist.forEach((item) => {
            item.value = item.kode_buyer;
            item.label = item.name;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getproductlist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbynomor_produk?nomor_produk=" + this.isidata.noprodh;
      const urlAPIget = this.$apiurl + "produk_h/getdaftar_produk_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.productlist = resp.data.data;
          this.productlist.forEach((item) => {
            item.value = item.nomor_produk;
            item.label = item.nama_produk;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getnow() {
      const today = new Date();
      const tahun = today.getFullYear();
      var month = today.getMonth();
      if (String(month).length == 1) {
        month = "0" + (month + 1);
      } else {
        month;
      }
      var tgl = today.getDate();
      if (String(tgl).length == 1) {
        tgl = "0" + tgl;
      } else {
        tgl;
      }
      // const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      this.isidata.mulai = tahun + "-" + month + "-" + tgl;
      this.isidata.selesai = tahun + "-" + month + "-" + tgl;
      this.isidata.tglorderbuyer = tahun + "-" + month + "-" + tgl;
    },
    async saveData() {
      this.loading = true;
      var ordbyr = this.isidata.odernobuyer;
      var tglo = this.isidata.tglorderbuyer;
      var qtyo = this.isidata.qtyorderbuyer;
      var noteso = this.isidata.notesorderbuyer;
      var nopro = this.valueproduct;
      var kodeby = this.valuebuyer;
      var st = this.isidata.mulai;
      var end = this.isidata.selesai;
      var pak = this.isidata.pack;
      var cm = this.isidata.cmt;
      var fo = this.isidata.fob;
      var ship = this.isidata.shipment;
      var tem = this.isidata.termcond;
      var con = this.isidata.cons;
      var cur = this.isidata.curr;
      var cval = this.isidata.cmtval;
      var stat = this.isidata.status;

      if (nopro == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kodeby == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ordbyr == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Date Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtyo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Order Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (st == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Start Date Production can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (end == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "End Date Production can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        // const tokenlogin = localStorage.getItem("token");
        var kodeuser = sessionStorage.getItem("kodeuser");
        var paramdata = {
          order_number: ordbyr,
          tanggal_order: tglo,
          kode_buyer: kodeby,
          qty: qtyo,
          notes: noteso,
          nomor_produk: nopro,
          kode_user: kodeuser,
          tanggal_produksi_start: st,
          tanggal_produksi_end: end,
          packaging: pak,
          cmt: cm,
          fob_value: fo,
          shipment: ship,
          term: tem,
          consignee: con,
          currency: cur,
          status: stat,
          cmt_value: cval
        };

        // console.log(paramdata);

        var tokenlogin = sessionStorage.getItem("token");
        // var urlAPIsavebrg = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/savebuyer_order";
        var urlAPIsavedata = this.$apiurl + "buyer_order/savebuyer_order";

        var headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .post(urlAPIsavedata, paramdata, { headers })
          .then(() => {
            var urlapitriggerreqcreatebom =
              this.$apiurl +
              "buyer_order/create_request_material_based_on_schedule";
            var dtparam = {
              nomor_produk: nopro,
              tanggal_produksi_start: this.isidata.mulai,
              order_number: ordbyr,
              kode_user: kodeuser
            };
            axios
              .post(urlapitriggerreqcreatebom, dtparam, { headers })
              .then(() => {
                var urlapitriggercreatepackinglistbasedonorder =
                  this.$apiurl +
                  "buyer_order/create_packing_list_based_on_order";
                var dtparamcreatepackinglistbasedonorder = {
                  nomor_produk: nopro,
                  tanggal_produksi_start: this.isidata.mulai,
                  order_number: ordbyr,
                  kode_user: kodeuser
                };

                axios
                  .post(
                    urlapitriggercreatepackinglistbasedonorder,
                    dtparamcreatepackinglistbasedonorder,
                    { headers }
                  )
                  .then(() => {
                    swal({
                      icon: "success",
                      title: "Success",
                      text: "Save data successfully",
                      showConfirmButton: false
                    });
                    this.loading = false;
                    this.$router.push({ name: "buyerorder" });
                  })
                  .catch((err) => {
                    console.log(err);
                    this.loading = false;
                  });
              })
              .catch((err) => {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: err.response.data,
                  showConfirmButton: false
                });
                console.log(err);
                this.loading = false;
              });
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
